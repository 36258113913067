interface SliderProps {
  image: string;
  title: string;
}

export const sliderData: SliderProps[] = [
  {
    image: '/assets/devices/go-e_Charger_Phoenix.png',
    title: 'go-e Charger PRO',
  },
  {
    image: '/assets/devices/go-e_Charger_V4_Fix.png',
    title: 'go-e Charger Gemini Flex',
  },
  {
    image: '/assets/devices/go-eCharger.png',
    title: 'go-e Charger HOMEfix/HOME+',
  },
  {
    image: '/assets/devices/go-e_Controller.png',
    title: 'go-e Controller',
  },
  {
    image: '/assets/devices/go-e_Controller_Ultralight.png',
    title: 'go-e Controller Ultralight',
  },
];
